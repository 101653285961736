export async function getData() {
  return getJson("data");
}

export async function submitScorecard(user_id, game, scorecard) {
  return apiRequest(
    "scorecards",
    "PUT",
    {
      "Content-Type": "application/json",
    },
    JSON.stringify({
      user_id: user_id,
      game: game,
      scorecard: scorecard,
    })
  );
}

export async function updateUser(user, handicap) {
  return apiRequest(
    "user",
    "PUT",
    {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.accessToken}`,
    },
    JSON.stringify({ handicap: handicap })
  )
    .then((response) => response.text())
    .then((data) => JSON.parse(data));
}

function getApiUrl() {
  return process.env.NODE_ENV === "production"
    ? "https://api-h422ljwxva-uw.a.run.app"
    : "http://192.168.50.57:8080";
}

async function getJson(api) {
  return apiRequest(api, "GET")
    .then((response) => response.text())
    .then((data) => JSON.parse(data));
}

async function apiRequest(api, method, headers, body) {
  const apiUrl = `${getApiUrl()}/${api}`;
  return fetch(`${apiUrl}`, {
    method: method,
    headers: headers,
    body: body,
  })
    .catch((err) => {
      console.log(
        `api: ${apiUrl}\nmethod: ${method}\nheaders: ${headers}\nbody: ${body}`
      );
      console.log(err);
      throw new Error("Failed to reach server");
    })
    .then((response) => {
      if (response.ok) {
        return response;
      } else {
        return response.text().then((text) => {
          throw new Error(text);
        });
      }
    });
}
