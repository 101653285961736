import React from "react";
import { Helmet } from "react-helmet";
import AppBar from "@mui/material/AppBar";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Box from "@mui/material/Box";
import EditNoteIcon from "@mui/icons-material/EditNote";
import HomeIcon from "@mui/icons-material/Home";
import InsightsIcon from "@mui/icons-material/Insights";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import PersonIcon from "@mui/icons-material/Person";
import { useTheme, styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import Home from "./home";
import Leaderboard from "./leaderboard";
import Stats from "./stats";
/*
import Scores from "./scores";
*/

const toolbarHeight = 80;
const bottomNavBottomPadding = IsStandalone() ? 4 : 0;

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  background: theme.palette.primary.main,
  position: "fixed",
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  alignItems: "flex-end",
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(2),
  minHeight: toolbarHeight,
  background: "rgba(0,0,0,0)",
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
}));

const BottomNavContainer = styled(Box)(({ theme }) => ({
  position: "fixed",
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: theme.palette.primary.main,
  paddingBottom: theme.spacing(bottomNavBottomPadding),
}));

const StyledBottomNavigation = styled(BottomNavigation)(({ theme }) => ({
  background: theme.palette.primary.main,
}));

const StyledBottomNavigationAction = styled(BottomNavigationAction)(
  ({ theme }) => ({
    color: theme.palette.primary.contrastText,
    "&.Mui-selected": {
      color: theme.palette.secondary.main,
    },
  })
);

export default function MobileApp({ data, error }) {
  const [page, setPage] = React.useState("leaderboard");
  const theme = useTheme();

  return (
    <React.Fragment>
      <Helmet>
        <meta name="theme-color" content={theme.palette.primary.main} />
      </Helmet>
      <StyledAppBar elevation={0}>
        <StyledToolbar>
          <Box component="img" src="masters/logo.png" height={48} mb={0.5} />
          <Typography
            fontSize={24}
            fontFamily="Azalea"
            lineHeight={1}
            color={theme.palette.secondary.main}
          >
            Ball Shaggers
          </Typography>
        </StyledToolbar>
      </StyledAppBar>
      <Box
        pb={theme.spacing(
          5 /* height of bottom nav */ +
            bottomNavBottomPadding /* bottom padding */ +
            2 /* padding */
        )}
      >
        <StyledToolbar />
        {page === "home" && <Home />}
        {page === "leaderboard" && <Leaderboard data={data} error={error} />}
        {page === "stats" && <Stats data={data} error={error} />}
      </Box>
      <BottomNavContainer>
        <StyledBottomNavigation
          value={page}
          onChange={(event, newValue) => {
            setPage(newValue);
          }}
        >
          <StyledBottomNavigationAction value="home" icon={<HomeIcon />} />
          <StyledBottomNavigationAction
            value="leaderboard"
            icon={<LeaderboardIcon />}
          />
          <StyledBottomNavigationAction value="stats" icon={<InsightsIcon />} />
          <StyledBottomNavigationAction
            value="scores"
            icon={<EditNoteIcon />}
          />
        </StyledBottomNavigation>
      </BottomNavContainer>
    </React.Fragment>
  );
}

function IsStandalone() {
  return window.matchMedia("(display-mode: standalone)").matches;
}
