import { Box, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import { Table } from "./table";

const COLUMN_PADDING = { p: 1, pt: 2, pb: 2 };
const COLUMNS = {
  name: {
    id: "name",
    label: "Name",
    align: "left",
    sx: {
      flexGrow: 1,
      width: "auto",
      whiteSpace: "nowrap",
      textTransform: "capitalize",
      ...COLUMN_PADDING,
    },
  },
  total: {
    id: "total",
    label: "T",
    align: "right",
    sortable: ["desc", "asc"],
    sorted: "desc",
    sx: { flexGrow: 0, width: "auto", ...COLUMN_PADDING },
  },
};

export function TournamentScorecard({ data }) {
  const columns = [
    COLUMNS.name,
    COLUMNS.total,
    ...data.games.map((game, index) => ({
      id: game.game_id,
      label: `R${index + 1}`,
      align: "right",
      sortable: ["desc", "asc"],
      sx: { flexGrow: 0, width: "auto", p: 1, pt: 2, pb: 2 },
    })),
  ];

  const rows = Object.entries(data.scores).map(([user, scores]) => {
    let r = Object.entries(scores).map(([game, score]) => [game, score.total]);
    r.push(["name", `${data.users[user].name} (${data.users[user].handicap})`]);
    r.push([
      "total",
      Object.values(scores).reduce((tot, score) => tot + score.total, 0),
    ]);
    return Object.fromEntries(r);
  });

  return <Table columns={columns} rows={rows} />;
}

export function GameScorecard({ game, data }) {
  if (game.type === "wolf") {
    return (
      <GenericGolfScorecard
        game={game}
        data={data}
        handlerFn={HandleWolfCategory}
      />
    );
  }
  return <GenericGolfScorecard game={game} data={data} />;
}

function GenericGolfScorecard({ game, data, handlerFn = HandleGolfCategory }) {
  /* Generic golf scorecard has rows for the typical course categories (hole,
   * index, par), as well as typical golf scores (gross, net), and other
   * generic per-hole points. */
  const course = data.courses?.[game?.course?.course_id];

  const columns = [COLUMNS.name, COLUMNS.total];

  const subColumns = [
    {
      id: "category",
      label: "",
      align: "left",
      sx: {
        flexGrow: 2,
        width: "auto",
        whiteSpace: "nowrap",
        p: 1,
        pt: 0.5,
        pb: 0.5,
      },
    },
    {
      id: "total",
      label: "Total",
      align: "right",
      sx: { flexGrow: 0, width: "auto", p: 1, pt: 0.5, pb: 0.5 },
    },
    ...Array.from(Array(course.hole_par.length).keys(), (x) => ({
      id: x,
      label: `${x + 1}`,
      align: "center",
      sx: {
        flexGrow: 0,
        width: "auto",
        p: 1,
        pt: 0.5,
        pb: 0.5,
      },
    })),
  ];

  const rows = Object.entries(data.scores).map(([user, userScores]) => {
    const name = `${data?.users?.[user]?.name} (${data?.users?.[user]?.handicap})`;
    const score = userScores?.[game.game_id];

    const ParSubRow = () => {
      const total = course?.hole_par.reduce((a, b) => a + b, 0);
      const strokes = score?.categories
        ?.find((y) => y.key === "Strokes Given")
        ?.value.map((value) => value);

      if (strokes && strokes.some((stroke) => stroke > 0)) {
        return {
          category: "Par",
          total: total,
          ...Array.from(Array(course?.hole_par.length).keys(), (i) => (
            <ParWithStrokesSymbol strokes={strokes[i]}>
              {course?.hole_par[i]}
            </ParWithStrokesSymbol>
          )),
        };
      }

      return {
        category: "Par",
        total: total,
        ...course.hole_par,
      };
    };

    const subRows = [
      { category: "Index", ...course.hole_index },
      ParSubRow(),
      ...score?.categories.map((x) => handlerFn(x, score?.categories, course)),
    ].filter((x) => x !== null);

    console.log(score?.total);
    return {
      name: name,
      total: score?.total || 0,
      subComponent: subRows && subRows.length > 0 && (
        <Table columns={subColumns} rows={subRows} />
      ),
    };
  });

  return <Table columns={columns} rows={rows} expandable={true} />;
}

export function Rules({ game }) {
  return (
    <Typography fontFamily="Tiempos Headline" fontSize={24} mt={6}>
      Rules
    </Typography>
  );
}

function HandleGolfCategory(category, categories, course) {
  const total = category.value.reduce((a, b) => a + b, 0);

  if (category.key === "Index") return null;
  if (category.key === "Par") return null;
  if (category.key === "Strokes Given") return null;

  if (category.key.endsWith("Gross") || category.key.endsWith("Net")) {
    const par = course?.hole_par.map((par) => par);
    return {
      category: category.key,
      total: total,
      ...Array.from(Array(course?.hole_par.length).keys(), (i) => (
        <NetToParSymbol toPar={category.value[i] - par[i]}>
          {category.value[i]}
        </NetToParSymbol>
      )),
    };
  }

  return {
    category: category.key,
    total: total,
    ...category.value,
  };
}

function HandleWolfCategory(category, categories, course) {
  if (category.key === "Wolf") {
    return {
      category: category.key,
      ...category.value.map((x) =>
        x ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <Box component="img" src="wolf.jpg" sx={{ height: "1.3em" }} />
          </Box>
        ) : (
          ""
        )
      ),
    };
  }

  return HandleGolfCategory(category, categories, course);
}

function ParWithStrokesSymbol({ strokes, children }) {
  return (
    <Grid container spacing={0}>
      <Grid
        item
        fontSize={12}
        display="flex"
        alignItems="center"
        justifyContent="center"
        xs={12}
      >
        {strokes > 0
          ? (String.fromCharCode("0x2022") + " ").repeat(strokes)
          : String.fromCharCode("0x00A0")}
      </Grid>
      <Grid
        item
        display="flex"
        alignItems="center"
        justifyContent="center"
        xs={12}
      >
        {children}
      </Grid>
    </Grid>
  );
}

const CenteredBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  p: 0,
  m: 0,
}));

function Circle({ size, color, sx, children }) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <CenteredBox
        sx={{
          width: size,
          height: size,
          borderRadius: "50%",
          border: `1px solid ${color}`,
          ...sx,
        }}
      >
        {children}
      </CenteredBox>
    </Box>
  );
}

function Square({ size, color, sx, children }) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <CenteredBox
        sx={{
          width: size,
          height: size,
          border: `1px solid ${color}`,
          ...sx,
        }}
      >
        {children}
      </CenteredBox>
    </Box>
  );
}

function NetToParSymbol({ toPar, children }) {
  if (toPar <= -3) {
    return (
      <Circle size="1.8em" color="deepskyblue">
        <Circle size="1.4em" color="deepskyblue">
          {children}
        </Circle>
      </Circle>
    );
  } else if (toPar === -2) {
    return (
      <Circle size="1.8em" color="teal">
        <Circle size="1.4em" color="teal">
          {children}
        </Circle>
      </Circle>
    );
  } else if (toPar === -1) {
    return (
      <Circle size="1.6em" color="firebrick">
        {children}
      </Circle>
    );
  } else if (toPar === 0) {
    return children;
  } else if (toPar === 1) {
    return (
      <Square size="1.6em" color="purple">
        {children}
      </Square>
    );
  } else if (toPar === 2) {
    return (
      <Square size="1.8em" color="teal">
        <Square size="1.4em" color="teal">
          {children}
        </Square>
      </Square>
    );
  } else {
    return (
      <Square size="1.8em" color="orange">
        <Square size="1.4em" color="orange">
          {children}
        </Square>
      </Square>
    );
  }
}
