import { Box, Paper, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";

export default function Home() {
  return (
    <>
      <Box m={2}>
        <Typography fontFamily="Tiempos Headline" fontSize={24}>
          Welcome
        </Typography>
        <Typography mt={2}>Dear Ball Shaggers,</Typography>
        <Typography mt={2}>
          Some chatGPT shit here. Formal invite below:
        </Typography>
        <Box display="flex" justifyContent="center" alignItems="center" mt={4}>
          <Paper elevation={8} square={true}>
            <Box padding={2} paddingLeft={8} paddingRight={8}>
              <Stack alignItems="center">
                <Box component="img" src="masters/logo_bw.png" height="100px" />
                <Typography
                  fontFamily="Invitation"
                  fontSize={24}
                  align="center"
                  mt={2}
                >
                  The Board of Governors
                </Typography>

                <Typography
                  fontFamily="Invitation"
                  fontSize={18}
                  align="center"
                >
                  of the
                </Typography>

                <Typography
                  fontFamily="Invitation"
                  fontSize={24}
                  align="center"
                >
                  Ball Shaggers
                </Typography>

                <Typography
                  fontFamily="Invitation"
                  fontSize={18}
                  align="center"
                >
                  respectfully requests <br />
                  the honor of your presence at the <br />
                  Two Thousand and Twenty Five
                </Typography>

                <Typography
                  fontFamily="Invitation"
                  fontSize={24}
                  align="center"
                >
                  Ball Shaggers Invitational
                </Typography>

                <Typography
                  fontFamily="Invitation"
                  fontSize={18}
                  align="center"
                >
                  to be held at <br />
                  Palm Springs, California <br />
                  the twenty-fifth through the <br />
                  twenty-ninth of January
                </Typography>

                <Typography
                  fontFamily="Chairman"
                  fontSize={24}
                  align="center"
                  mt={4}
                >
                  Peter Berczi
                </Typography>

                <Typography
                  fontFamily="Chairman"
                  fontSize={18}
                  align="center"
                  lineHeight={0.8}
                >
                  Shagger
                </Typography>
              </Stack>
            </Box>
          </Paper>
        </Box>

        <Typography fontFamily="Tiempos Headline" fontSize={24} mt={6}>
          Important Information
        </Typography>
        <Typography mt={2} ml={2}>
          <b>When:</b> January 25-29
        </Typography>
        <Typography ml={2}>
          <b>Where:</b> Palm Springs, California
        </Typography>
      </Box>
    </>
  );
}
