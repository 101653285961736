import { useState } from "react";
import { Box, Paper, Stack, TableContainer, Typography } from "@mui/material";

import { ErrorAlert } from "./error_alert";
import { Loading } from "./loading";
import { Table } from "./table";

export default function Stats({ data, error }) {
  if (error) {
    return <ErrorAlert error={error} />;
  }

  if (data === null) {
    return <Loading />;
  }

  return (
    <Box m={2}>
      <Stack>
        <Typography fontFamily="Tiempos Headline" fontSize={24}>
          Statistics
        </Typography>
      </Stack>
    </Box>
  );

  /*
  return (
    <Stack sx={{ display: "flex" }} justifyContent="space-between" spacing={2}>
      <PageSelect
        page={state.page}
        pageList={state.pageList}
        callbackFn={(event) => {
          setState((prevState) => ({ ...prevState, page: event.target.value }));
        }}
      />
      <PageTable
        page={state.page}
        courses={state.courses}
        games={state.games}
        scores={state.scores}
        users={state.users}
      />
      <PageRules page={state.page} games={state.games} />
    </Stack>
  );
  */
}
