import { Alert, AlertTitle } from "@mui/material";

export function ErrorAlert({ error }) {
  return (
    <Alert severity="error">
      <AlertTitle>{error.title}</AlertTitle>
      {error.msg}
    </Alert>
  );
}
