import React from "react";
import { useTheme } from "@mui/material/styles";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import {
  Box,
  Collapse,
  IconButton,
  TableContainer,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";

export function Table({ columns, rows, containerProps, tableProps }) {
  /* Display order for the rows */
  const [rowIndices, setRowIndices] = React.useState(
    DefaultRowIndices(rows.length)
  );

  /* Which column is currently sorted, if any */
  const [sortColumn, setSortColumn] = React.useState(null);

  /* Sort direction for actively sorted column, if any */
  const [sortDirection, setSortDirection] = React.useState(null);

  /* Sorts rows based on input column */
  const handleSortRequest = (column) => {
    let col = sortColumn;
    let dir = sortDirection;
    let indices = rowIndices;

    if (col === column.id) {
      dir =
        column.sortable[
          (column.sortable.findIndex((element) => element === dir) + 1) %
            column.sortable.length
        ];
    } else {
      col = column.id;
      dir = column.sortable[0];
    }

    if (dir !== null) {
      indices = indices.sort((a, b) => {
        const a_val = rows[a][col];
        const b_val = rows[b][col];
        const sign = dir === "asc" ? 1 : -1;
        return a_val < b_val ? sign * -1 : a_val > b_val ? sign * 1 : 0;
      });
    }
    setSortColumn(col);
    setSortDirection(dir);
    setRowIndices(indices);
  };

  /* If the table is not sorted but there is at least one default-sorted
   * column, sort the table */
  if (sortColumn === null && columns.some((x) => x.sorted)) {
    columns.filter((x) => x.sorted).forEach((x) => handleSortRequest(x));
  }

  const expandable = rows.some((x) => x.subComponent);

  return (
    <TableContainer {...containerProps}>
      <MuiTable {...tableProps}>
        <TableHead>
          <TableRow>
            {expandable && (
              <TableCell
                key="expandarrow"
                sx={{ flexGrow: 0, width: "auto", p: 0 }}
              />
            )}
            {columns.map((x) => (
              <TableCell key={x.id} align={x.align} sx={x.sx}>
                {x.sortable ? (
                  <TableSortLabel
                    active={sortColumn === x.id && sortDirection !== null}
                    direction={sortDirection ? sortDirection : "asc"}
                    onClick={(ev) => {
                      handleSortRequest(x);
                    }}
                  >
                    {x.label}
                  </TableSortLabel>
                ) : (
                  x.label
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rowIndices.map((x) => (
            <ExpandableRow
              row={rows[x]}
              columns={columns}
              expandable={expandable}
              key={x}
            />
          ))}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
}

function ExpandableRow({ row, columns, expandable }) {
  const [expanded, setExpanded] = React.useState(false);

  const theme = useTheme();

  return (
    <React.Fragment>
      <TableRow>
        {expandable && (
          <TableCell
            key="expandarrow"
            sx={{ flexGrow: 0, width: "auto", p: 0 }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
                width: "100%",
                height: "100%",
              }}
            >
              <IconButton
                size="small"
                onClick={() => setExpanded(!expanded)}
                sx={{ p: 0 }}
              >
                {expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              </IconButton>
            </Box>
          </TableCell>
        )}
        {Object.values(columns).map((column) => (
          <TableCell key={column.id} align={column.align} sx={column.sx}>
            {row[column.id]}
          </TableCell>
        ))}
      </TableRow>

      {row.subComponent && (
        <TableRow>
          <TableCell colSpan={columns.length + 1} sx={{ p: 0, border: 0 }}>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <div
                style={{
                  marginBottom: `${theme.spacing(4)}`,
                  overflowX: "auto",
                  maxWidth: `calc(100vw - ${theme.spacing(4)}`,
                }}
              >
                {row.subComponent}
              </div>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
}

function DefaultRowIndices(n) {
  return [...Array(n).keys()];
}
