import React from "react";
import PullToRefresh from "pulltorefreshjs";
import { useState } from "react";
import { isMobile } from "react-device-detect";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";

import { getData } from "./api_utils";
import MobileApp from "./mobile_app";

export default function App() {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [refreshing, setRefreshing] = React.useState(true);

  const theme = createTheme({
    palette: {
      primary: {
        light: "rgb(42,84,60)",
        main: "#005e23",
        dark: "rgb(24,50,40)",
        contrastText: "#ffffff",
      },
      secondary: {
        light: "#e52534",
        main: "#fcf400",
        dark: "#e52534",
        contrastText: "#000000",
      },
      background: {
        default: "white",
      },
    },
    typography: {
      fontFamily: "Tiempos",
      fontSize: 14,
    },
  });

  if (error === null && data === null) {
    getData()
      .then((data) => {
        setData(data);
      })
      .catch((err) =>
        setError({ title: "Failed to fetch data", msg: err.message })
      );
  }

  PullToRefresh.init({
    onRefresh() {
      window.location.reload();
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <MobileApp data={data} error={error} />
    </ThemeProvider>
  );
}
