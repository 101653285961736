import ReactDOM from "react-dom/client";
import './index.css';

import App from "./app";
import { initFirebase } from "./auth_utils.js";

initFirebase();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
