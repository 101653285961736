import { useState } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";

import { ErrorAlert } from "./error_alert";
import { TournamentScorecard, GameScorecard, Rules } from "./games";
import { Loading } from "./loading";

export default function Leaderboard({ data, error }) {
  const [round, setRound] = useState(null);

  if (error) {
    return <ErrorAlert error={error} />;
  }

  if (data === null) {
    return <Loading />;
  }

  const roundList = [
    {
      value: "tournament",
      label: "Tournament",
    },
    ...data.games.map((x) => ({ value: x.game_id, label: x.name })),
  ];

  if (round === null) {
    setRound(roundList[0].value);
    return <Loading />;
  }

  return (
    <Box m={2}>
      <Stack justifyContent="space-between" spacing={2}>
        <Typography fontFamily="Tiempos Headline" fontSize={24}>
          Leaderboard
        </Typography>
        <PageSelect
          label="Round"
          page={round}
          pageList={roundList}
          onChange={(event) => setRound(event.target.value)}
        />
        {round === "tournament" && <TournamentScorecard data={data} />}
        {data.games.map(
          (game) =>
            round === game.game_id && (
              <Stack>
                <GameScorecard game={game} data={data} />
                <Rules game={game} />
              </Stack>
            )
        )}
      </Stack>
    </Box>
  );
}

function PageSelect({ label, page, pageList, onChange }) {
  return (
    <FormControl fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select value={page} label={label} onChange={onChange}>
        {pageList.map((option) => (
          <MenuItem key={option.value} value={option.value} sx={{ p: 2 }}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
